<template>
  <v-container class="pa-0 heritage-scroll flex-column">
    <v-skeleton-loader
      v-if="loading"
      :loading="loading"
      type="article, list-item-two-line, actions"
      style="background-color: white"
    />
    <v-sheet
      v-if="!loading"
      rounded
      class="blue-grey lighten-4"
      style="border-radius: 4px 4px 0 0 !important"
    >
      <v-row
        fill-height
        fluid
        no-gutters
        class="my-2 align-center justify-space-between"
      >
        <v-avatar
          :color="configuration.tacheSelectionnee.couleur"
          size="24"
          class="mx-2"
        ></v-avatar>
        <span class="mx-2 break-word">
          {{ configuration.tacheSelectionnee.nom }}
        </span>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :test-auto="nomPage + '_fermerFenetreTache'"
              small
              v-bind="attrs"
              class="mx-2 ml-auto"
              v-on="on"
              @click="clearTacheSelectionnee"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <span>Fermer la fenêtre</span>
        </v-tooltip>
      </v-row>
    </v-sheet>
    <v-sheet
      v-if="!loading"
      class="pa-4 heritage-scroll flex-column"
      style="border-radius: 0 0 4px 4px !important"
    >
      <div class="heritage-scroll flex-column">
        <!-- Informations sur la tâche -->
        <v-row no-gutters>
          <span>
            <span class="font-weight-medium"> Projet : </span>
            {{ nomProjet }}
          </span>
        </v-row>
        <v-row no-gutters>
          <span>
            <span class="font-weight-medium">Responsable : </span>
            {{ getPrenomNom(tache.idRessourceResponsable) }}
          </span>
        </v-row>
        <v-row no-gutters>
          <span>
            <span class="font-weight-medium">Date de création : </span>
            {{ dateDeCreation }}
          </span>
        </v-row>

        <v-divider class="my-2"></v-divider>

        <!-- Avancement -->
        <slider-avancement-tache :tache="tache" :nom-page="nomPage" />

        <!-- Indicateurs -->
        <v-row no-gutters class="justify-space-between">
          <span>
            <span class="font-weight-medium">Prévu : </span>
            {{ tache.tempsPrevu }} JH
          </span>
          <span v-show="tache.actif">
            <span class="font-weight-medium">Projeté : </span>
            {{ getFormatedfloat(tache.tempsProjete) }} JH
          </span>
        </v-row>
        <v-row no-gutters class="justify-space-between">
          <span>
            <span class="font-weight-medium">Consommé : </span>
            {{ tache.tempsPointe }} JH
          </span>
          <span v-show="tache.actif">
            <span class="font-weight-medium">Reste : </span>
            {{ getFormatedfloat(tache.tempsResteAFaire) }} JH
          </span>
        </v-row>

        <v-divider class="my-2"></v-divider>

        <!-- Actions sur tâche -->
        <v-row no-gutters class="justify-space-between px-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="IamTravailleur"
                :test-auto="nomPage + '_pointerSurTache'"
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="redirigerVersPointageTache"
              >
                <v-icon>mdi-calendar-month</v-icon>
              </v-btn>
            </template>
            <span>Pointer sur cette tâche</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :test-auto="nomPage + '_statistiquesTache'"
                small
                v-bind="attrs"
                v-on="on"
                @click="$router.push(`/statistiques/tache/${tache.idTache}`)"
              >
                <v-icon>mdi-poll-box</v-icon>
              </v-btn>
            </template>
            <span>Statistiques cumulées de la tâche</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :test-auto="nomPage + '_modifierTache'"
                small
                v-bind="attrs"
                v-on="on"
                @click="ouvrirFormulaireTacheModification"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Modifier la tâche</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :test-auto="nomPage + '_ajouterSousTache'"
                small
                v-bind="attrs"
                v-on="on"
                @click="ouvrirFormulaireTacheCreationSousTache"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Ajouter une sous-tâche</span>
          </v-tooltip>

          <!-- Dupliquer la tâche -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                :test-auto="nomPage + '_dupliquerTache'"
                icon
                v-bind="attrs"
                v-on="on"
                @click="dupliquerLaTache"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Dupliquer la tâche</span>
          </v-tooltip>

          <v-divider inset vertical></v-divider>

          <!-- Ajouter une note -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                :test-auto="nomPage + '_ajouterNote'"
                icon
                v-bind="attrs"
                @click="ouvrirFormulaireNoteCreation"
                v-on="on"
              >
                <v-icon>mdi-note-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Ajouter une note</span>
          </v-tooltip>

          <!-- Ajouter un label -->
          <!-- TODO: Enable labels end backend supports it -->
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon v-bind="attrs" v-on="on">
                <v-icon>mdi-label-outline</v-icon>
              </v-btn>
            </template>
            <span>Ajouter un label</span>
          </v-tooltip> -->

          <!-- TODO: only chefDeProjet and Suppléants can delete a task 
          make the list of suppléant available in /projet/1 endpoints to be able to hide it
          -->

          <v-divider
            v-if="tache.tempsPointe === 0 && tache.terminal"
            inset
            vertical
          ></v-divider>
          <v-tooltip v-if="tache.tempsPointe === 0 && tache.terminal" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :test-auto="nomPage + '_supprimerLaTache'"
                small
                v-bind="attrs"
                v-on="on"
                @click="supprimerLaTache"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Supprimer la tâche</span>
          </v-tooltip>
        </v-row>

        <v-divider class="my-2"></v-divider>

        <!-- Ajout d'un travailleur -->
        <div class="scrollable-content">
          <v-row
            v-show="tache.actif"
            no-gutters
            class="pb-2 align-center justify-space-between"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :test-auto="nomPage + '_afficherListeTravailleurs'"
                  v-bind="attrs"
                  class="mx-2"
                  v-on="on"
                  @click="
                    afficherListeTravailleurs = !afficherListeTravailleurs
                  "
                >
                  <v-icon v-if="afficherListeTravailleurs">
                    mdi-arrow-down-drop-circle
                  </v-icon>
                  <v-icon v-else>mdi-arrow-right-drop-circle-outline</v-icon>
                </v-btn>
              </template>
              <span v-if="afficherListeTravailleurs">
                Masquer les ressources affectées
              </span>
              <span v-else>Afficher les ressources affectées</span>
            </v-tooltip>
            <v-autocomplete
              v-model="valeurInputTravailleur"
              :test-auto="nomPage + '_ajouterRessource'"
              :items="listeDesTravailleurs"
              :loading="loadingInputTravailleur"
              :search-input.sync="searchInputTravailleur"
              auto-select-first
              label="Ajouter une ressource..."
              clearable
              solo
              dense
              flat
              outlined
              hide-details
              @input="ajoutTravailleur"
            ></v-autocomplete>
          </v-row>

          <!-- Liste des travailleurs -->
          <div
            v-if="
              afficherListeTravailleurs &&
                tache.travailleurs &&
                tache.travailleurs.length > 0
            "
          >
            <div
              v-for="(objetTravailleur, index) in tache.travailleurs"
              :key="objetTravailleur.idRessource"
              class="pl-3"
              style="min-height: 32px"
              no-gutters
            >
              <v-row no-gutters class="align-center">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      color="red darken-3"
                      :disabled="objetTravailleur.tempsPointe > 0"
                      v-on="on"
                      @click="
                        supprimerTravailleur(objetTravailleur.idTravailleur)
                      "
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Retirer la ressource</span>
                </v-tooltip>
                <span class="mx-3 vignette-total-heures">
                  <!-- 1 chiffre après la virgule uniquement si non-entier -->
                  {{
                    `${Math.round(objetTravailleur.tempsPointe * 10) / 10} JH`
                  }}
                </span>
                <span class="truncated-if-too-long">
                  {{ getPrenomNom(objetTravailleur.idRessource) }}
                </span>
              </v-row>
              <v-divider
                v-if="index < tache.travailleurs.length - 1"
              ></v-divider>
            </div>
          </div>

          <!-- Notes  -->
          <v-row no-gutters>
            <v-col v-if="tache.notes && tache.notes.length > 0">
              <v-divider class="my-2"></v-divider>

              <v-row no-gutters>
                <!-- Append '1A' to color hexa code in order to have opacity set to 10% -->
                <v-card
                  v-for="note in tache.notes"
                  :key="note.idNote"
                  :color="tache.couleur + '1A'"
                  class="my-1 px-2 mt-4 py-4 elevation-1 flex-grow-1"
                >
                  <v-row no-gutters class="align-center justify-space-between">
                    <!-- Dernier auteur et date de modification -->
                    <span class="mr-auto font-weight-light">
                      {{ getPrenomNom(note.idRessource) }} le
                      {{ new Date(note.derniereModification).toLocaleString() }}
                    </span>

                    <!-- Modifier une note -->
                    <v-btn
                      v-model="fabNotes[note.idNote]"
                      :test-auto="nomPage + '_modifierNote'"
                      :color="configuration.tacheSelectionnee.couleur"
                      :dark="!couleurEstClaire"
                      absolute
                      right
                      top
                      fab
                      x-small
                      @click="ouvrirFormulaireNoteModification(note.idNote)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-row>
                  <!-- Texte de la note préformaté; balise 'pre' pour conserver la structure -->
                  <span class="break-word">{{ note.commentaire }}</span>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { EventHub } from "@/event-hub.js";
import { getContrastYIQ, formatFloat } from "@/utils/transform";
import Api from "@/api/kuberact";
import SliderAvancementTache from "@/components/SliderAvancementTache";

export default {
  components: {
    SliderAvancementTache,
  },
  props: {
    nomPage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: true,
    saving: false,
    afficherListeTravailleurs: true,
    valeurInputTravailleur: null,
    loadingInputTravailleur: false,
    searchInputTravailleur: "",
    fabNotes: {},
  }),
  computed: {
    ...mapState("utilisateur", ["configuration", "utilisateur"]),
    ...mapState("entreprise", ["projets", "utilisateurs"]),
    ...mapGetters("entreprise", [
      "getPrenomNom",
      "utilisateurAndTacheInSameEntreprise",
      "getIdRessourceByTache",
    ]),
    IamTravailleur: function() {
      const array1 = this.tache.travailleurs.map((t) => t.idRessource);
      const array2 = this.utilisateur.ressources.map((t) => t.idRessource);
      return array1.some((value) => array2.includes(value)) && this.tache.actif;
    },
    tache: function() {
      return this.configuration.tacheSelectionnee;
    },
    nomProjet: function() {
      return this.projets[this.tache.idProjet].nomProjet;
    },
    dateDeCreation: function() {
      return new Date(this.tache.dateCreationTache).toLocaleDateString();
    },
    listeDesTravailleurs() {
      return Object.values(this.utilisateurs)
        .filter(
          (utilisateur) =>
            utilisateur.actif &&
            this.utilisateurAndTacheInSameEntreprise(utilisateur, this.tache)
        )
        .map((ressource) => ({
          value: this.getIdRessourceByTache(ressource, this.tache),
          text: `${ressource.prenom} ${ressource.nom}`,
          disabled: this.tache.travailleurs.includes(ressource.idRessource),
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    couleurEstClaire() {
      return getContrastYIQ(this.tache.couleur);
    },
  },
  watch: {
    "configuration.idTacheSelectionnee": function() {
      this.initialiserDetailTache();
    },
  },
  created() {
    this.initialiserDetailTache();
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("utilisateur", [
      "recupererTacheSelectionnee",
      "clearTacheSelectionnee",
    ]),
    ...mapActions("entreprise", [
      "duplicateTache",
      "updateTache",
      "deleteTache",
    ]),
    getFormatedfloat: formatFloat,
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    initialiserDetailTache() {
      this.loading = true;
      this.recupererTacheSelectionnee()
        .catch((err) => {
          console.log(err);
          this.clearTacheSelectionnee();
        })
        .finally(() => (this.loading = false));
    },
    ouvrirFormulaireTacheModification() {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "tache",
        id: this.tache.idTache,
      });
    },
    ouvrirFormulaireTacheCreationSousTache() {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "tache",
        id: this.tache.idTache,
        idProjetPourTache: this.tache.idProjet,
      });
    },
    ouvrirFormulaireNoteCreation() {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "note",
        id: 0,
      });
    },
    ouvrirFormulaireNoteModification(idNote) {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "note",
        id: idNote,
      });
    },
    redirigerVersPointageTache() {
      // La présence du bouton qui active cette fonction admet que
      // l'id de la tâche affichée est déjà stocké dans le store
      // sous utilisateur.configuration.idTacheSelectionnee
      // Donc ce bouton c'est du sucre syntaxique et on fait juste une redirection
      this.$router.push("/pointages");
    },
    dupliquerLaTache() {
      this.duplicateTache({
        id: this.tache.idTache,
        tache: {
          idRessourceResponsable: this.tache.idRessourceResponsable,
          idRessourceCreateur: this.getIdRessourceByTache(
            this.utilisateur,
            this.projets[this.tache.idProjet]
          ),

          idTacheMere: this.tache.idTacheMere,
          couleur: this.getRandomColor(),
          tempsPrevu: this.tache.tempsPrevu,
          idProjet: this.tache.idProjet,
          nom: this.tache.nom,
        },
      });
    },
    supprimerLaTache() {
      this.loading = true;
      this.deleteTache({
        idTache: this.tache.idTache,
        idProjet: this.tache.idProjet,
      })
        .then(() => {
          this.clearTacheSelectionnee();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.loading = false));
    },
    ajoutTravailleur(idRessource) {
      if (idRessource >= 0) {
        this.loadingInputTravailleur = true;
        Api.createTravailleur({
          idRessource: idRessource,
          idTache: this.tache.idTache,
        })
          .then(async () => {
            // TODO: remove la récupération et faire l'injection de la réponse dans le store
            await this.recupererTacheSelectionnee();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.valeurInputTravailleur = null;
            this.loadingInputTravailleur = false;
            this.searchInputTravailleur = "";
          });
      }
    },
    supprimerTravailleur(idTravailleur) {
      this.loadingInputTravailleur = true;
      Api.destroyTravailleur(idTravailleur)
        .then(async () => {
          // TODO: remove la récupération et faire la suppresion du travailleur dans le store
          await this.recupererTacheSelectionnee();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.loadingInputTravailleur = false));
    },
    //
  },
};
</script>

<style scoped>
.break-word {
  flex: 1;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}
.truncated-if-too-long {
  flex: 1;
  min-width: 0; /* or some value */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vignette-total-heures {
  min-width: 70px;
  text-align: right;
  border: solid;
  border-width: 1px;
  border-radius: 10%;
  float: right;
  padding-right: 5px;
  padding-left: 5px;
}
</style>
