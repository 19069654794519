<template>
  <v-container
    fluid
    fill-height
    pa-2
    class="conteneur-page gradient-bleu align-start heritage-scroll flex-row"
  >
    <v-col class="pa-0 pr-1 flex-shrink-1 heritage-scroll fill-height">
      <details-projets :nom-page="nomPage"/>
    </v-col>

    <v-col
      v-if="configuration.idTacheSelectionnee && $vuetify.breakpoint.smAndUp"
      cols="5"
      class="pa-0 pl-1 heritage-scroll fill-height"
    >
      <detail-tache :nom-page="nomPage"/>
    </v-col>
  </v-container>
</template>
<script>
import DetailsProjets from "@/views/DetailsProjets";
import DetailTache from "@/views/DetailTache";
import { mapState } from "vuex";

export default {
  components: {
    DetailsProjets,
    DetailTache,
  },
  data: () => ({
    nomPage:"accueil"
    //
  }),
  computed: {
    ...mapState("utilisateur", ["configuration"]),
    //
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>
<style >
</style>
