var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 heritage-scroll flex-column"},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"flex-grow-1",staticStyle:{"background-color":"white"},attrs:{"loading":_vm.loading,"type":"list-item-avatar, divider,divider, card-heading, card-heading,\n     card-heading, card-heading, card-heading"}}):_vm._e(),(!_vm.loading)?_c('v-sheet',{staticClass:"blue-grey lighten-4 mb-1",attrs:{"rounded":""}},[(!_vm.projetsAAfficherTries.length)?_c('div',{staticClass:"text-center pa-8"},[_c('router-link',{attrs:{"to":"/projets"}},[_vm._v(" Vos projets favoris s'afficheront ici en complément de celui sélectionné ")])],1):_c('v-row',{staticClass:"align-center flex-nowrap",attrs:{"no-gutters":""}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"test-auto":_vm.nomPage + '_rechercher',"placeholder":"... ","label":"Saisir votre recherche puis appuyer sur la touche Entrée","outlined":"","dense":"","type":"text","autofocus":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.search = $event.target.value},"click:clear":function($event){_vm.search = ''}}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mx-2"},'span',attrs,false),on),[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"test-auto":_vm.nomPage + '_filtre_tachesTerminees',"hide-details":"","on-icon":"mdi-flag-plus","off-icon":"mdi-flag-remove-outline"},model:{value:(_vm.afficherLesTachesTermineesEtDesactivees),callback:function ($$v) {_vm.afficherLesTachesTermineesEtDesactivees=$$v},expression:"afficherLesTachesTermineesEtDesactivees"}})],1)]}}],null,false,505451797)},[(_vm.afficherLesTachesTermineesEtDesactivees)?_c('span',[_vm._v(" Masquer les tâches terminées ")]):_c('span',[_vm._v(" Afficher les tâches terminées ")])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.favoris.length > 0),expression:"favoris.length > 0"}],staticClass:"mx-2",attrs:{"test-auto":_vm.nomPage + '_pointagesProjets',"icon":""},on:{"click":function($event){return _vm.$router.push('/pointages/projets')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-month ")])],1)]}}],null,false,594058604)},[_c('span',[_vm._v("Visualiser les pointages des projets")])])],1)],1):_vm._e(),(_vm.processingDeplacementTache)?_c('v-progress-linear',{staticClass:"mt-n1",attrs:{"indeterminate":"","rounded":"","height":"4","color":"blue-grey lighten-3"}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"scrollable-content"},[_c('v-expansion-panels',{attrs:{"popout":"","multiple":""},model:{value:(_vm.positionProjetsOuverts),callback:function ($$v) {_vm.positionProjetsOuverts=$$v},expression:"positionProjetsOuverts"}},_vm._l((_vm.projetsAAfficherTries),function(projet){return _c('v-expansion-panel',{key:projet.idProjet},[_c('drop',{on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDrop.apply(void 0, [ 0, projet.idProjet ].concat( argsArray ))},"dragenter":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDragenterProject.apply(void 0, [ projet.idProjet ].concat( argsArray ))},"dragover":_vm.handleDragoverProject}},[_c('v-expansion-panel-header',[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"max-width":"30px"}},[_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"input-value":_vm.favoris.includes(projet.idProjet),"on-icon":"mdi-star","readonly":_vm.processingFavori,"disabled":_vm.processingFavori,"off-icon":"mdi-star-outline","color":"orange","hide-details":"","dense":""},on:{"change":function($event){return _vm.processFavori($event, projet.idProjet)}},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1),_c('v-col',{staticClass:"flex-grow-1 flex-shrink-0"},[_c('span',{staticClass:"body-2 font-weight-medium"},[_vm._v(" "+_vm._s(projet.nomProjet)+" ")])]),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"flex-grow-1 flex-shrink-1",staticStyle:{"max-width":"80px"}},[_c('v-progress-linear',{staticClass:"mr-1",attrs:{"color":"teal lighten-3","height":"20","rounded":"","striped":"","value":_vm.detailsProjets[projet.idProjet].avancementCumule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.floor(value) || 0)+"%")])]}}],null,true)})],1):_vm._e(),_c('v-col',{staticClass:"px-2",staticStyle:{"max-width":"120px"}},[_c('indicateurs',{staticClass:"mr-1",attrs:{"temps-prevu-cumule":_vm.detailsProjets[projet.idProjet].tempsPrevuCumule,"temps-pointe-cumule":_vm.detailsProjets[projet.idProjet].tempsPointeCumule,"temps-reste-a-faire-cumule":_vm.detailsProjets[projet.idProjet].tempsResteAFaireCumule}})],1),_c('v-col',{staticClass:"d-flex flex-grow-0 flex-shrink-1",staticStyle:{"max-width":"112px"}},[_c('v-tooltip',{staticClass:"modifierProjet",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
                        _vm.verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet(
                          projet.idProjet
                        )
                      )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ouvrirFormulaireProjet(projet.idProjet)}},nativeOn:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modifier le projet")])]),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push(
                          ("/statistiques/projet/" + (projet.idProjet))
                        )}},nativeOn:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-poll-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Statistiques du projet")])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ouvrirExportExcel(projet.idProjet)}},nativeOn:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}],null,true)},[_c('span',[_vm._v("Export Excel")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ouvrirFormulaireTacheCreationTache(projet.idProjet)}},nativeOn:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Ajouter une tâche")])])],1)],1)],1)],1),_c('v-expansion-panel-content',[_c('v-treeview',{attrs:{"items":_vm.detailsProjetsFiltres[projet.idProjet].filles,"item-key":"idTache","item-children":"filles","item-text":"nom","dense":"","open":_vm.tachesOuvertes[projet.idProjet],"search":_vm.search},on:{"update:open":function($event){return _vm.$set(_vm.tachesOuvertes, projet.idProjet, $event)}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
                        var item = ref.item;
return [_c('drag',{attrs:{"effect-allowed":"move","drop-effect":"move","draggable":!_vm.processingDeplacementTache,"transfer-data":item}},[_c('drop',{on:{"drop":function($event){
                        var i = arguments.length, argsArray = Array(i);
                        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDrop.apply(void 0, [ item.idTache, item.idProjet ].concat( argsArray ))},"dragenter":function($event){
                        var i = arguments.length, argsArray = Array(i);
                        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDragenterTask.apply(void 0, [ item ].concat( argsArray ))},"dragover":_vm.handleDragoverTask}},[_c('barre-de-progression-tache',{attrs:{"tache":item}})],1)],1)]}}],null,true)})],1)],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }