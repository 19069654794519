<template>
  <v-container fluid fill-height pa-0>
    <v-sheet class="d-flex flex-column flex-grow-1">
      <v-row no-gutters>
        <v-col
          cols="12"
          class="nb-prevu police"
          :class="enRetard ? 'en-retard' : 'a-l-heure'"
        >
          <span>
            {{ getFormatedfloat(tempsPrevuCumule) }}
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6" class="nb-pointe police">
          <span>
            {{ getFormatedfloat(tempsPointeCumule) }}
          </span>
        </v-col>
        <v-col cols="6" class="nb-reste police">
          <span>
            {{ getFormatedfloat(tempsResteAFaireCumule) }}
          </span>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import { formatFloat } from "@/utils/transform";
export default {
  props: {
    tempsPrevuCumule: {
      type: Number,
      default: 0,
    },
    tempsPointeCumule: {
      type: Number,
      default: 0,
    },
    tempsResteAFaireCumule: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    //
  }),
  computed: {
    enRetard() {
      return (
        this.tempsPointeCumule + this.tempsResteAFaireCumule >
        this.tempsPrevuCumule
      );
    },
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    getFormatedfloat: formatFloat,
  },
};
</script>
<style scoped>
.a-l-heure {
  background-color: #81c784; /* #BEF781; */
}

.en-retard {
  background-color: #e57373; /* #FFA500; */
}

.police {
  /* min-width: 70px !important; */
  font-size: small;
  font-weight: 500;
  text-align: center;
  line-height: 1.3;
  /* padding: 2px; */
  /* text-justify: auto; */
}
.nb-prevu {
  color: #212121;
  /* padding: 2px; */
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.nb-pointe {
  background-color: #455a64; /* #7F8C8D; */
  border-bottom-left-radius: 3px;
  color: white;
}

.nb-reste {
  background-color: #0277bd; /* #58ACFA; */
  border-bottom-right-radius: 3px;
  color: white;
}
</style>
