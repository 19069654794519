import Api from "@/api/kuberact";


const recupTachesEtTravailleursProjet = function(tableau) {
  let tachesEtTravailleursProjet = new Array();
  let noeudFormate = {};
  let nomCumule = "";
  let nomTacheMere = "";
  let idCumule = "";
  let rang = 0;
  //On récupère les travailleurs au meme niveau
  function recupererLesTravailleur(tableTravailleur, noeud){
    if (Array.isArray(tableTravailleur) && tableTravailleur.length)
    {
      tableTravailleur.forEach((travailleur) => {         
        travailleur.nom = travailleur.idRessource,
        noeudFormate = {
          id : "tr-"+travailleur.idTravailleur,
          idCumule : noeud.idCumule,
          categorie : "travailleur",
          idTacheMere : noeud.idTache,
          nomTacheMere : noeud.nom,
          nomCumule : noeud.nomCumule,
          rank : noeud.rank+1,
          tempsPointe : travailleur.tempsPointe.toFixed(1),
          idRessource : travailleur.idRessource,
          estMere : false,
          nomPrenom : travailleur.idRessource,
        };
        tachesEtTravailleursProjet.push(noeudFormate); 
        noeudFormate = {};
      });
    };
  }; // fin recupererLesTravailleur()
  //On récupère les taches au meme niveau
  function recupererLesTaches(noeud) {
    if (noeud.idTache) 
    {
      if (nomCumule === undefined) {
        noeud.nomCumule = noeud.nom;
        noeud.idCumule = noeud.idTache;
        noeud.rank = 1;
        noeud.nomTacheMere = "";
      } 
      else {
        noeud.nomCumule = nomCumule +" - " + noeud.nom;            
        noeud.idCumule = idCumule +" - " + noeud.idTache;            
        noeud.rank = rang+1;
        noeud.nomTacheMere = nomTacheMere;
      }
      if (Array.isArray(noeud.filles)) {
        noeud.estMere = true;      
      } 
      else {
        noeud.estMere = false;
      }
      noeud.categorie = "tache";
      noeud.id = noeud.idTache;
      //on récupère les travailleurs de la tache
      Api.getTache(noeud.idTache).then((response) => 
      recupererLesTravailleur(response.data.travailleurs, noeud));
      //on change le json en objet 
      //(parce que travailleur n'est pas un json mais un objet)
      noeudFormate = {
        id : "ta-"+noeud.id,
        idCumule : noeud.idCumule,
        categorie : noeud.categorie,
        idTacheMere : noeud.idTacheMere,
        nomTacheMere : noeud.nomTacheMere,
        nom : noeud.idTache+" - "+noeud.nom,
        nomCumule : noeud.nomCumule,
        rank : noeud.rank,
        tempsPointe : noeud.tempsPointe.toFixed(1),
        idTache : noeud.idTache,
        avancement :Math.round(noeud.avancement*100)+"%",
        avancementCumule :noeud.avancementCumule+"%",
        tempsPrevu : noeud.tempsPrevu.toFixed(1),
        tempsProjete : noeud.tempsProjete.toFixed(1),
        tempsResteAFaire : noeud.tempsResteAFaire.toFixed(1),
        estMere : noeud.estMere,
      };
      tachesEtTravailleursProjet.push(noeudFormate);      
      nomCumule = "";
      idCumule = "";
      rang = 0;
      noeudFormate = {};
    }
    if (Array.isArray(noeud.filles) && noeud.filles.length) 
    {
      noeud.filles.forEach((fille) => {
        nomCumule = noeud.nomCumule;
        idCumule = noeud.idCumule;
        rang = noeud.rank;
        nomTacheMere = noeud.nom;
        recupererLesTaches(fille);
      });
    };
  }; // fin recupererLesTaches()
  recupererLesTaches(tableau);
  return tachesEtTravailleursProjet
}; //fin tableauTachesEtTravailleursProjet

export { recupTachesEtTravailleursProjet };