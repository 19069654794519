<template>
  <!-- Barre de filtrage des projets  -->

  <v-container class="pa-0 heritage-scroll flex-column">
    <v-skeleton-loader
      v-if="loading"
      :loading="loading"
      type="list-item-avatar, divider,divider, card-heading, card-heading,
       card-heading, card-heading, card-heading"
      class="flex-grow-1"
      style="background-color: white"
    />

    <v-sheet v-if="!loading" rounded class="blue-grey lighten-4 mb-1">
      <div v-if="!projetsAAfficherTries.length" class="text-center pa-8">
        <router-link to="/projets">
          Vos projets favoris s'afficheront ici en complément de celui
          sélectionné
        </router-link>
      </div>
      <v-row v-else no-gutters class="align-center flex-nowrap">
        <v-text-field
          :test-auto="nomPage + '_rechercher'"
          placeholder="... "
          label="Saisir votre recherche puis appuyer sur la touche Entrée"
          outlined
          dense
          type="text"
          autofocus
          hide-details
          clearable
          class="pa-4"
          clear-icon="mdi-close-circle-outline"
          @keydown.enter="search = $event.target.value"
          @click:clear="search = ''"
        ></v-text-field>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" class="mx-2" v-on="on">
              <v-checkbox
                v-model="afficherLesTachesTermineesEtDesactivees"
                :test-auto="nomPage + '_filtre_tachesTerminees'"
                hide-details
                class="mt-0 pt-0"
                on-icon="mdi-flag-plus"
                off-icon="mdi-flag-remove-outline"
              ></v-checkbox>
            </span>
          </template>
          <span v-if="afficherLesTachesTermineesEtDesactivees">
            Masquer les tâches terminées
            <!-- TODO: enable filter to hide inactive task
               -> requirement: actif key in details-projets  -->
            <!-- Masquer les tâches terminées et désactivées -->
          </span>
          <span v-else>
            Afficher les tâches terminées
            <!-- Afficher les tâches terminées et désactivées -->
          </span>
        </v-tooltip>
        <v-divider vertical></v-divider>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="favoris.length > 0"
              :test-auto="nomPage + '_pointagesProjets'"
              v-bind="attrs"
              icon
              class="mx-2"
              v-on="on"
              @click="$router.push('/pointages/projets')"
            >
              <v-icon>mdi-calendar-month </v-icon>
            </v-btn>
          </template>
          <span>Visualiser les pointages des projets</span>
        </v-tooltip>
      </v-row>
    </v-sheet>
    <v-progress-linear
      v-if="processingDeplacementTache"
      indeterminate
      rounded
      height="4"
      color="blue-grey lighten-3"
      class="mt-n1"
    ></v-progress-linear>
    <div v-if="!loading" class="scrollable-content">
      <v-expansion-panels v-model="positionProjetsOuverts" popout multiple>
        <v-expansion-panel
          v-for="projet in projetsAAfficherTries"
          :key="projet.idProjet"
        >
          <drop
            @drop="handleDrop(0, projet.idProjet, ...arguments)"
            @dragenter="handleDragenterProject(projet.idProjet, ...arguments)"
            @dragover="handleDragoverProject"
          >
            <v-expansion-panel-header>
              <v-row no-gutters class="align-center">
                <v-col style="max-width: 30px">
                  <v-checkbox
                    :input-value="favoris.includes(projet.idProjet)"
                    on-icon="mdi-star"
                    :readonly="processingFavori"
                    :disabled="processingFavori"
                    off-icon="mdi-star-outline"
                    color="orange"
                    hide-details
                    class="pt-0 mt-0"
                    dense
                    @click.native.stop
                    @change="processFavori($event, projet.idProjet)"
                  ></v-checkbox>
                </v-col>
                <v-col style="" class="flex-grow-1 flex-shrink-0">
                  <span class="body-2 font-weight-medium">
                    {{ projet.nomProjet }}
                  </span>
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.smAndUp"
                  style="max-width: 80px"
                  class="flex-grow-1 flex-shrink-1"
                >
                  <v-progress-linear
                    class="mr-1"
                    color="teal lighten-3"
                    height="20"
                    rounded
                    striped
                    :value="detailsProjets[projet.idProjet].avancementCumule"
                  >
                    <template v-slot:default="{ value }">
                      <strong>{{ Math.floor(value) || 0 }}%</strong>
                    </template>
                  </v-progress-linear>
                </v-col>
                <v-col class="px-2" style="max-width: 120px">
                  <indicateurs
                    class="mr-1"
                    :temps-prevu-cumule="
                      detailsProjets[projet.idProjet].tempsPrevuCumule
                    "
                    :temps-pointe-cumule="
                      detailsProjets[projet.idProjet].tempsPointeCumule
                    "
                    :temps-reste-a-faire-cumule="
                      detailsProjets[projet.idProjet].tempsResteAFaireCumule
                    "
                  />
                </v-col>
                <v-col
                  style="max-width: 112px"
                  class="d-flex flex-grow-0 flex-shrink-1"
                >
                  <v-tooltip bottom class="modifierProjet">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="
                          verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet(
                            projet.idProjet
                          )
                        "
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click.native.stop
                        @click="ouvrirFormulaireProjet(projet.idProjet)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Modifier le projet</span>
                  </v-tooltip>
                  <v-tooltip v-if="$vuetify.breakpoint.smAndUp" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click.native.stop
                        @click="
                          $router.push(
                            `/statistiques/projet/${projet.idProjet}`
                          )
                        "
                      >
                        <v-icon>mdi-poll-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Statistiques du projet</span>
                  </v-tooltip>
                  <v-tooltip v-if="$vuetify.breakpoint.smAndUp" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click.native.stop
                        @click="ouvrirExportExcel(projet.idProjet)"      
                      >
                        <v-icon>mdi-microsoft-excel</v-icon>
                      </v-btn>
                    </template>
                    <span>Export Excel</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click.native.stop
                        @click="
                          ouvrirFormulaireTacheCreationTache(projet.idProjet)
                        "
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Ajouter une tâche</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </drop>
          <v-expansion-panel-content>
            <v-treeview
              :items="detailsProjetsFiltres[projet.idProjet].filles"
              item-key="idTache"
              item-children="filles"
              item-text="nom"
              dense
              :open.sync="tachesOuvertes[projet.idProjet]"
              :search="search"
            >
              <template v-slot:label="{ item }">
                <drag
                  effect-allowed="move"
                  drop-effect="move"
                  :draggable="!processingDeplacementTache"
                  :transfer-data="item"
                >
                  <drop
                    @drop="
                      handleDrop(item.idTache, item.idProjet, ...arguments)
                    "
                    @dragenter="handleDragenterTask(item, ...arguments)"
                    @dragover="handleDragoverTask"
                  >
                    <barre-de-progression-tache :tache="item" />
                  </drop>
                </drag>
              </template>
            </v-treeview>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Indicateurs from "@/components/Indicateurs";
import BarreDeProgressionTache from "@/components/BarreDeProgressionTache";
import { EventHub } from "@/event-hub.js";
import { recupTachesEtTravailleursProjet } from "@/utils/export";
import { verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet } from "@/utils/conditions";

export default {
  components: { Indicateurs, BarreDeProgressionTache },
  props: {
    nomPage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    search: "",
    loading: true,
    processingDeplacementTache: false,
    processingFavori: false,
    afficherLesTachesTermineesEtDesactivees: true,
    positionProjetsOuverts: [],
    tachesOuvertes: {},
    verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet:
      verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet,
  }),
  computed: {
    ...mapState("entreprise", ["projets", "utilisateurs", "detailsProjets"]),
    ...mapState("utilisateur", ["utilisateur", "configuration"]),
    ...mapGetters("utilisateur", ["favoris"]),
    projetsAAfficherTries: function() {
      //on affiche que les favoris present dans la liste de projet
      //evite les erreur du au maillage entre entreprise
      //si un utilisateur est enlevé d'un l'entreprise mais ayant encore un favoris dans celle si
      //TODO supprimer de la liste des favoris les projet des entrepise ou l'utilisateur a des ressource inactive
      let projetsAAfficher = new Set(
        this.favoris.filter((favori) => favori in this.projets)
      );
      if (this.configuration.idProjetSelectionne > 0) {
        projetsAAfficher.add(this.configuration.idProjetSelectionne);
      }
      return (
        [...projetsAAfficher]
          .map((favori) => this.projets[favori])
          // Sort by project name
          .sort((a, b) =>
            this.projets[a.idProjet].nomProjet.localeCompare(
              this.projets[b.idProjet].nomProjet
            )
          )
      );
    },
    detailsProjetsFiltres: function() {
      const self = this;
      function retirerLesTachesTerminees(noeud) {
        // S'applique aussi bien sur un projet que sur une tâche
        // Récursion bottom-up
        if (Array.isArray(noeud.filles) && noeud.filles.length) {
          noeud.filles.forEach((fille) => {
            retirerLesTachesTerminees(fille);
          });
          // Filter
          if (!self.afficherLesTachesTermineesEtDesactivees) {
            noeud.filles = noeud.filles.filter(
              (fille) => fille.avancementCumule < 100
              // TODO: enable filter to hide inactive task -> 
              //requirement: actif key in details-projets
              // && fille.actif
            );
          }

          // Sort
          noeud.filles = noeud.filles.sort((a, b) =>
            a.nom.localeCompare(b.nom)
          );
        }
      }

      let deepCopyDetailsProjets = JSON.parse(
        JSON.stringify(this.detailsProjets)
      );
      // Filter and sort tasks
      Object.values(deepCopyDetailsProjets).forEach((projet) => {
        retirerLesTachesTerminees(projet);
      });
      return deepCopyDetailsProjets;
    },
    //
  },
  watch: {
    positionProjetsOuverts: function() {
    },
    "configuration.tacheSelectionnee": function({ idProjet, idTacheMere }) {
      // Gestion du dépliement automatique des projets et tâches lors de la création d'une tâche
      if (this.configuration.idTacheSelectionnee) {
        if (
          idTacheMere !== null &&
          !this.tachesOuvertes[idProjet].includes(idTacheMere)
        ) {
          // ouvrir la tâche mère s'il y a création d'une sous-tâche côté détail-tâche
          this.tachesOuvertes[idProjet].push(idTacheMere);
        } else if (idTacheMere === null) {
          // Si une tâche racine est créée et que le projet n'est pas ouvert, alors on l'ouvre
          const indexProjet = this.projetsAAfficherTries.findIndex(
            (projet) => projet.idProjet === idProjet
          );
          if (
            indexProjet >= 0 &&
            !this.positionProjetsOuverts.includes(indexProjet)
          ) {
            this.positionProjetsOuverts.push(indexProjet);
          }
        }
      }
    },
  },
  created() {
    const configuration = this.configuration.detailsProjets;
    const idProjetsAAfficherTries = this.projetsAAfficherTries.map(
      (projet) => projet.idProjet
    );
    // Ouverture des tâches sauvegardées dans le state
    if (Object.keys(configuration.tachesOuvertes).length) {
      idProjetsAAfficherTries.forEach((idProjet) => {
        if (idProjet in configuration.tachesOuvertes) {
          this.tachesOuvertes[idProjet] = [
            ...configuration.tachesOuvertes[idProjet],
          ];
        }
      });
    }

    // Ouverture des projets sauvegardés dans le state
    if (configuration.projetsOuverts.length) {
      idProjetsAAfficherTries.forEach((idProjet, index) => {
        if (configuration.projetsOuverts.includes(idProjet)) {
          this.positionProjetsOuverts.push(index);
        }
      });
    }

    // Récupération des arbres de tâches
    if (
      Array.isArray(idProjetsAAfficherTries) &&
      idProjetsAAfficherTries.length
    ) {
      this.updateDetailsProjets(idProjetsAAfficherTries)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  },
  mounted() {
    //
  },
  beforeDestroy() {
    // Sauvegarde du contexte utilisateur lors du changement de vue
    // TODO: sauvegarder en BD pour résister à un reload de la page
    this.sauvegardeConfigurationDetailsProjets({
      tachesOuvertes: this.tachesOuvertes,
      projetsOuverts: this.positionProjetsOuverts.map(
        (positionProjetOuvert) =>
          this.projetsAAfficherTries[positionProjetOuvert].idProjet
      ),
    });
    //
  },
  methods: {
    ...mapActions("entreprise", ["updateDetailsProjets", "moveTache"]),
    ...mapActions("utilisateur", [
      "modifierFavori",
      "sauvegardeConfigurationDetailsProjets",
    ]),
    ouvrirFormulaireProjet(idProjet) {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "projet",
        id: idProjet,
      });
    },
    ouvrirFormulaireTacheCreationTache(idProjet) {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "tache",
        idProjetPourTache: idProjet,
        id: 0,
      });
    },
    ouvrirPointagesGlobauxProjets() {
      console.log("A développer");
      // console.log(this.favoris.map((favori) => this.projets[favori]));
      // const projetsAffiches = [];
      // this.$router.push({
      //   name: "PointagesGlobale",
      //   query: { listIdProjets: projetsAffiches },
      // });
    },
    processFavori(favoriEtatSouhaite, idProjet) {
      // Uniquement si suppresion d'un favori
      this.processingFavori = true;
      const positionProjet = this.projetsAAfficherTries.findIndex(
        (projet) => projet.idProjet === idProjet
      );
      this.modifierFavori({
        favoriEtatSouhaite,
        idProjet,
      })
        .then(() => {
          if (!favoriEtatSouhaite) {
            // Recule d'un cran les projets au-dela du favori supprimé pour conserver
            // les bons projets ouverts
            this.positionProjetsOuverts = this.positionProjetsOuverts.map(
              (position) =>
                position > positionProjet ? position - 1 : position
            );
          }
        })
        .finally(() => {
          this.processingFavori = false;
        });
    }, //fin processFavori
    handleDragoverTask(tacheSource, event) {
      event.dataTransfer.dropEffect = this.dropEffectOnDragover;
    },
    handleDragenterTask(tacheCible, tacheSource) {
      // Précaution sur le drag&drop
      // Vérification que l'on traite bien une tâche en entrée
      // On regarde aussi s'il y a pas déjà un traitement du drop en cours car le props 'draggable' sur drag ne semble pas fiable
      if (
        !this.processingDeplacementTache &&
        typeof tacheSource === "object" &&
        tacheSource !== null &&
        "idTache" in tacheSource
      ) {
        // Fonction de récupéreration les 'idTache' de l'arbre dans un tableau avec récursion sur la clé 'filles'
        const getIdTaches = ({ idTache, filles }) =>
          filles ? [idTache, ...filles.flatMap(getIdTaches)] : [idTache];

        // On veut bloquer le drop sur la tache source, ses tâches filles récursivement et sa tâche mère
        let idsTacheABloquer = getIdTaches(tacheSource);
        if ("idTacheMere" in tacheSource)
          idsTacheABloquer.push(tacheSource.idTacheMere);

        this.dropEffectOnDragover = idsTacheABloquer.includes(
          tacheCible.idTache
        )
          ? "none"
          : "move";
      } else {
        this.dropEffectOnDragover = "none";
      }
    }, //fin handleDragenterTask
    handleDragoverProject(tacheSource, event) {
      event.dataTransfer.dropEffect = this.dropEffectOnDragover;
    },
    handleDragenterProject(idProjetCible, tacheSource) {
      // bloquer le drop d'une tâche racine vers le même projet
      // On regarde aussi s'il y a pas déjà un traitement du drop en cours car le props 'draggable' sur drag ne semble pas fiable
      if (
        !this.processingDeplacementTache &&
        typeof tacheSource === "object" &&
        tacheSource !== null &&
        idProjetCible >= 0 &&
        "idTache" in tacheSource
      ) {
        this.dropEffectOnDragover =
          !this.processingDeplacementTache &&
          tacheSource.idProjet === idProjetCible &&
          tacheSource.idTacheMere === null
            ? "none"
            : "move";
      } else {
        this.dropEffectOnDragover = "none";
      }
    }, //fin handleDragenterProject
    handleDrop(idTacheCible, idProjetCible, tacheSource) {
      // passer idTacheCible = 0 pour déplacer la tâche à la racine d'un projet
      // car l'API attends un 0 sur la tache mère pour déplacer une tâche à la racine
      this.processingDeplacementTache = true;
      this.moveTache({
        idTacheCible,
        idProjetCible,
        tacheSource,
      })
        .catch((err) => console.log(err))
        .finally(() => (this.processingDeplacementTache = false));
    }, //fin handleDrop

    ouvrirExportExcel(idProjet){  
      let tableauTachesEtTravailleursProjet = 
      recupTachesEtTravailleursProjet( this.detailsProjetsFiltres[idProjet]);
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "export-projet",
        id: idProjet,
        data: {
          tableauTachesEtTravailleurs: tableauTachesEtTravailleursProjet,
          projetFiltre : this.projets[idProjet],
          avancementCumuleProjet : this.detailsProjets[idProjet].avancementCumule,
          idProjetFiltre : idProjet,
          title: ("Details du projet " + this.projets[idProjet].nomProjet),
        },
      });
    }, //fin ouvrirExportExcel

  }, //fin methods
} //fin export
</script>
<style>
.v-expansion-panel-header {
  padding: 8px;
}
.v-expansion-panel-content__wrap {
  padding: 0px;
}
.modifierProjet {
  display: flex !important;
  width: 28px;
  height: 24px;
}
</style>
