<template>
  <!-- TODO: code legacy à nettoyer -->
  <v-container
    fluid
    fill-height
    style="font-size: small"
    class="text-left py-1 pl-1"
  >
    <v-row no-gutters>
      <v-col>
        <v-progress-linear
          :value="tache.avancementCumule"
          color="#BEF781"
          rounded
          class="curseur"
          :class="{
            'tache-selectionnees': estSelectionnee,
          }"
          height="100%"
          @click="
            estSelectionnee
              ? clearTacheSelectionnee()
              : updateIdTacheSelectionnee(tache.idTache)
          "
        >
          <span
            v-if="nbSousTaches"
            class="ml-2 vignette-nb-sous-taches caption font-weight-medium"
            >{{ nbSousTaches }}</span
          >
          <span
            class="ml-2 truncated-if-too-long content-progress-linear progress-linear-title"
          >
            {{ tache.nom }}
          </span>
          <span class="mx-1 caption font-weight-bold">
            {{ tache.avancementCumule }}%
          </span>
        </v-progress-linear>
      </v-col>
      <v-col class="flex-grow-0 flex-shrink-1" style="min-width: 100px">
        <indicateurs
          style="max-height: 35px"
          class="pl-1"
          :temps-prevu-cumule="tache.tempsPrevuCumule"
          :temps-pointe-cumule="tache.tempsPointeCumule"
          :temps-reste-a-faire-cumule="tache.tempsResteAFaireCumule"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Indicateurs from "@/components/Indicateurs";
export default {
  components: {
    Indicateurs,
  },
  props: {
    tache: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    //
  }),

  computed: {
    ...mapState("utilisateur", ["configuration"]),
    nbSousTaches() {
      return Array.isArray(this.tache.filles) ? this.tache.filles.length : 0;
    },
    estSelectionnee() {
      return this.configuration.idTacheSelectionnee === this.tache.idTache;
    },
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("utilisateur", [
      "updateIdTacheSelectionnee",
      "clearTacheSelectionnee",
    ]),
  },
};
</script>
<style >
.vignette-nb-sous-taches {
  min-width: 30px;
  text-align: center;
  border: solid;
  border-color: grey;
  border-width: 1px;
  border-radius: 20%;
}

.tache-selectionnees {
  box-shadow: 0px 0px 3px 3px rgba(3, 169, 244, 0.6) !important;
}

.truncated-if-too-long {
  flex: 1;
  min-width: 0; /* or some value */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.curseur {
  cursor: pointer;
}
</style>
