<template>
  <v-row v-show="tache.actif" no-gutters class="align-center slider-component">
    <v-slider
      v-model="sliderAvancement"
      :label="verifierSiLaValeurDuSliderAChanger ? '' : 'Avancement (%)'"
      dense
      inverse-label
      class="align-center"
      track-color="blue-grey lighten-4"
      :color="tache.couleur"
      max="100"
      min="0"
      hide-details
      @click.native.stop
    >
      <template v-slot:prepend>
        <v-text-field
          v-model="sliderAvancement"
          :test-auto="nomPage+'_avancement'"
          class="mt-0 pt-0 mr-4"
          hide-details
          min="0"
          max="100"
          single-line
          type="number"
          style="width: 45px"
          @click.native.stop
        >
        </v-text-field>
      </template>
    </v-slider>
    <v-btn
      v-show="verifierSiLaValeurDuSliderAChanger"
      :color="tache.couleur"
      class="ml-3"
      :class="couleurEstClaire ? 'black--text' : 'white--text'"
      depressed-est
      small
      :loading="saving"
      @click="mettreAJourAvancement"
      @click.native.stop
    >
    Mettre à jour
    </v-btn>
  </v-row>
</template>
<script>
import Api from "@/api/kuberact";
import { mapActions} from "vuex";
import { getContrastYIQ } from "@/utils/transform";
export default({
    props:{
      tache:{
      type: Object,
      default: null,
      },
      nomPage:{
        type: String,
        default: "",
      }
    },
  data: () => ({
    sliderAvancement:0,
    saving:false,
  }),
  computed: {
    verifierSiLaValeurDuSliderAChanger(){
      return !(this.sliderAvancement==Math.floor(this.tache.avancement * 100));
    },
    couleurEstClaire() {
      return getContrastYIQ(this.tache.couleur);
    },
  },
  watch: {
    tache: function () {
      this.sliderAvancement = Math.floor(this.tache.avancement * 100)
    }
  },
  created() {
    this.sliderAvancement=Math.floor(this.tache.avancement*100);
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("entreprise", ["updateTache","updateDetailsProjets"]),
    ...mapActions("utilisateur", ["recupererTacheSelectionnee"]),
    mettreAJourAvancement() {
      this.saving = true;
      if(this.nomPage=="accueil"){
        this.updateTache({
            idTache: this.tache.idTache,
            idProjet: this.tache.idProjet,
            avancement: this.sliderAvancement / 100,
          }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.saving = false;
        });
        }
      else{
          Api.updateTache(this.tache.idTache,{
            idTache: this.tache.idTache,
            idProjet: this.tache.idProjet,
            avancement: this.sliderAvancement / 100,
          }).then(()=>{
            this.tache.avancement=this.sliderAvancement / 100;
          }).finally(() => (this.saving = false));
        }
      
    },
  },
})
</script>
<style scoped>
.v-btn:not(.v-btn--round).v-size--small {
  padding: 0 4.885px;
}
</style>